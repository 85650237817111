import { Injectable } from "@angular/core";
import { Messaging, getToken, onMessage, deleteToken, isSupported } from "@angular/fire/messaging";
import { Subject } from "rxjs";
import { getEnvironment } from "../environment/environment";
import { AlertService } from "./alert.service";
import { XtrasService } from "./xtras.service";

@Injectable({
    providedIn: "root",
})
export class FcmService {

    private backgroundMessageSubject = new Subject<any>();
    private clickedMessageSubject = new Subject<any>();
    private messageSubject = new Subject<any>();
    private playSoundSubject = new Subject<string>();

    backgroundMessage$ = this.backgroundMessageSubject.asObservable();
    clickedMessage$ = this.clickedMessageSubject.asObservable();
    message$ = this.messageSubject.asObservable();
    playSound$ = this.playSoundSubject.asObservable();

    constructor(
        private msg: Messaging,
        private alert: AlertService,
        private xServices: XtrasService,
    ){
        const environment = getEnvironment();
        if(environment.firebaseKeyMessage) {
            if(!isSupported()) {
                console.warn('Navegador no soportado');
                return;
            }
            // this.getToken().then();
        } else {
            console.warn('No se ha configurado la clave de firebase');
        }

        onMessage(this.msg, (payload) => {
            this.messageSubject.next(payload.data);
            this.playSound();
        });

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener('message', (event) => {
                if (event.data && event.data.type === 'BACKGROUND_NOTIFICATION') {
                    console.log('Background message received: ', event.data.payload);
                    this.backgroundMessageSubject.next(event.data.payload);
                    this.playSound();
                }
                if (event.data && event.data.type === 'CLICKED_NOTIFICATION') {
                    console.log('Clicked message received: ', event.data.payload);
                    this.clickedMessageSubject.next(event.data.payload);
                }
            });
        }

        const _window:any = window;
        _window.addEventListener('copyText', (event: CustomEvent) => {
            console.log('Evento recibido:', event.detail.text);
            this.copyText(event.detail.text);
        });

        this.xServices.loadInlineScriptBody(
            `
                function copyText(text) {
                    console.log(text);
                    const event = new CustomEvent('copyText', { 
                        detail: {
                            text: text
                        }
                    });
                    window.dispatchEvent(event);
                }
            `
        );
    }

    async getToken(): Promise<DataFCM> {
        const browserInfo = this.getNavigator();

        if (browserInfo.isMobile) {
            return {
                status: false,
                code: 'not-supported',
                message: 'Las notificaciones push no están completamente soportadas en navegadores móviles',
                navigator: browserInfo
            };
        }

        try {
            const environment = getEnvironment();
            if(environment.firebaseKeyMessage) {
                if(!isSupported() /* || browserInfo.code === 'firefox'*/) {
                    console.warn('Navegador no soportado');
                    return {
                        status: false,
                        code: 'not-supported',
                        message: 'Navegador no soportado',
                        navigator: browserInfo
                    };
                }
            } else {
                console.warn('No se ha configurado la clave de firebase');
                return {
                    status: false,
                    code: 'not-key',
                    message: 'No se ha configurado la clave de firebase',
                    navigator: browserInfo
                };
            }

            const permission = await Notification.requestPermission();
            if (permission !== 'granted') {
                console.warn('Permission not granted');
                return {
                    status: false,
                    code: 'permission',
                    message: 'Debe conceder el permiso de notificación al sitio web',
                    navigator: browserInfo
                };
            }
        
            if (!('serviceWorker' in navigator)) {
                console.warn('Service Worker not supported');
                return {
                    status: false,
                    code: 'not-supported',
                    message: 'Navegador no soportado',
                    navigator: browserInfo
                };
            }
        
            const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js', {
                type: 'module',
            });
            
            const token = await getToken(this.msg, {
                vapidKey: environment.firebaseKeyMessage,
                serviceWorkerRegistration: registration
            });
        
            return {
                status: true,
                code: 'success',
                message: 'Notificaciones permitidas',
                token: token,
                navigator: browserInfo
            };
        } catch (error) {
            console.error('Error in getToken:', error);
            return {
                status: false,
                code: 'error',
                message: `${error}`,
                navigator: browserInfo
            };
        }
    }
    
    async deleteToken() {
        await deleteToken(this.msg);
    }

    playSound() {
        this.playSoundSubject.next('/assets/sounds/notification-1.mp3');
    }

    getNavigator(): DataFCM['navigator'] {
        const userAgent = navigator.userAgent;
        const userAgentLower = userAgent.toLowerCase();
        let _window: any = window;
        
        const isMobile = this.isMobileDevice();
        
        const _navigator: any = navigator;
        const isBrave = !!(_navigator.brave && _navigator.brave.isBrave);
        
        const baseSettings = {
            isMobile,
            image: '/assets/img/browser-list/default.svg',
            settingsUrl: undefined,
            settingsHTML: undefined,
        };
        // Detección de navegadores
        if (isBrave) {
            return {
                ...baseSettings,
                name: 'Brave',
                code: 'brave',
                settingsUrl: 'brave://settings/content/notifications',
                settingsHTML: `
                    <p><b>1er. Paso:</b> Ingresando a <a href="javascript:void(0);" onclick="copyText('brave://settings/privacy')"><b>brave://settings/privacy</b></a> verifique que la opción <b>"Utiliza los servicios de Google para la mensajería push"</b> se encuentre habilitado como en la imagen.</p>
                    <a href="/assets/img/browser-list/brave-1.png" target="_blank"><img src="/assets/img/browser-list/brave-1.png" /></a>
                    <p><b>2do. Paso:</b> Ingresando a <a href="javascript:void(0);" onclick="copyText('brave://settings/content/siteDetails?site=${window.location.origin}')"><b>brave://settings/content/siteDetails?site=${window.location.origin}</b></a> verifique si la opción <b>Notificaciones</b> se encuentre habilitado como en la imagen.</p>
                    <a href="/assets/img/browser-list/brave-2.png" target="_blank"><img src="/assets/img/browser-list/brave-2.png" /></a>
                `,
                image: '/assets/img/browser-list/brave.svg'
            };
        } else if (userAgentLower.includes('firefox') && !userAgentLower.includes('seamonkey')) {
            return {
                ...baseSettings,
                name: 'Firefox',
                code: 'firefox',
                settingsUrl: 'about:preferences#privacy',
                image: '/assets/img/browser-list/firefox.svg'
            };
        } else if (userAgentLower.includes('edg') || userAgentLower.includes('edge')) {
            return {
                ...baseSettings,
                name: 'Microsoft Edge',
                code: 'edge',
                settingsUrl: `edge://settings/content/notifications`,
                settingsHTML: `
                    <p>Ingresando a <a href="javascript:void(0);" onclick="copyText('edge://settings/content/siteDetails?site=${window.location.origin}')"><b>edge://settings/content/siteDetails?site=${window.location.origin}</b></a> verifique si la opción <b>Notificaciones</b> se encuentre habilitado como en la imagen.</p>
                    <a href="/assets/img/browser-list/edge.png" target="_blank"><img src="/assets/img/browser-list/edge.png" /></a>
                `,
                image: '/assets/img/browser-list/edge.svg'
            };
        } else if (userAgentLower.includes('opr') && !!_window['opr']) {
            return {
                ...baseSettings,
                name: 'Opera',
                code: 'opera',
                settingsUrl: `opera://settings/content/notifications`,
                settingsHTML: `
                    <p>Ingresando a <a href="javascript:void(0);" onclick="copyText('opera://settings/content/siteDetails?site=${window.location.origin}')"><b>opera://settings/content/siteDetails?site=${window.location.origin}</b></a> verifique si la opción <b>Notificaciones</b> se encuentre habilitado como en la imagen.</p>
                    <a href="/assets/img/browser-list/opera.png" target="_blank"><img src="/assets/img/browser-list/opera.png" /></a>
                `,
                image: '/assets/img/browser-list/opera.svg'
            };
        } else if (userAgentLower.includes('chrome') && !userAgentLower.includes('edg')) {
            return {
                ...baseSettings,
                name: 'Google Chrome',
                code: 'chrome',
                settingsUrl: `chrome://settings/content/notifications`,
                settingsHTML: `
                    <p>Ingresando a <a href="javascript:void(0);" onclick="copyText('chrome://settings/content/siteDetails?site=${window.location.origin}')"><b>chrome://settings/content/siteDetails?site=${window.location.origin}</b></a> verifique si la opción <b>Notificaciones</b> se encuentre habilitado como en la imagen.</p>
                    <a href="/assets/img/browser-list/chrome.png" target="_blank"><img src="/assets/img/browser-list/chrome.png" /></a>
                `,
                image: '/assets/img/browser-list/chrome.svg'
            };
        } else if (userAgentLower.includes('safari') && !userAgentLower.includes('chrome')) {
            return {
                ...baseSettings,
                name: 'Safari',
                code: 'safari',
                settingsUrl: isMobile ? 'App-Prefs:NOTIFICATIONS_ID' : undefined,
                image: '/assets/img/browser-list/safari.svg'
            };
        } else if (userAgentLower.match(/android/)) {
            return {
                ...baseSettings,
                name: 'Android Browser',
                code: 'android',
                image: '/assets/img/browser-list/android.svg'
            };
        } else if (userAgentLower.match(/iphone|ipad|ipod/)) {
            return {
                ...baseSettings,
                name: 'iOS Browser',
                code: 'ios',
                settingsUrl: 'App-Prefs:NOTIFICATIONS_ID',
                image: '/assets/img/browser-list/ios.svg'
            };
        } else {
            return {
                ...baseSettings,
                name: userAgent,
                code: 'unknown',
            };
        }
    }
    
    private isMobileDevice(): boolean {
        const userAgent = navigator.userAgent.toLowerCase();
        
        // Detección de dispositivos móviles
        const isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile/i.test(userAgent);
        
        // Detección de tablets (pueden reportarse como móviles)
        const isTablet = /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/i.test(userAgent);
        
        // Verificar también por el tamaño de pantalla y capacidades táctiles
        const hasTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
        const isSmallScreen = window.innerWidth < 768;
        
        return (isMobile && !isTablet) || (hasTouchScreen && isSmallScreen);
    }

    public codeLabel(code: DataFCM['code']): string {
        switch (code) {
            case 'error':
                return 'Error';
            case 'not-supported':
                return 'Navegador no soportado';
            case 'not-key':
                return 'No "Key" FCM';
            case 'permission':
                return 'Conceder permiso';
            case 'success':
                return 'Permitido';
            default:
                return 'Indefinido'
        }
    }

    copyText(text: string) {
        navigator.clipboard.writeText(text);
        this.alert.alertCapYei({
            type: 'success',
            message: 'Link copiado',
            description: 'Pega el texto en una nueva pestaña. <img src="/assets/img/new-window.png" alt="Nueva ventana" style="border: 2px solid #DDD;border-radius: 10px;">',
            buttons: {
                cancel: { show: false },
            }
        });
    }
}

export interface DataFCM {
    status: boolean;
    code: 'error'|'not-supported'|'not-key'|'permission'|'success',
    message: string;
    token?: string;
    navigator: {
        settingsUrl?: string;
        settingsHTML?: string;
        name?: string;
        code: string;
        image: string;
        isMobile: boolean;
    }
}