import { Injectable } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private isLoading = false;
  private isLoadingAlt = false;
  constructor(
  ) { }

  dismiss() {
    if(this.isLoading) {
      $('.loading_wrapper.init').remove();
      this.isLoading = false;
    }
  }

  loadingShow(loader?: string, message?: string) {
    if(!this.isLoading) {
      // console.log('loading');
      let default_loader = '<div class="loads-ellipsis"><div></div><div></div><div></div><div></div></div>';
      if(loader && loader == 'loading_2'){
        loader = '<div class="loadingio-spinner-dual-ball"><div class="ldio-dualball"><div></div><div></div><div></div></div></div>';
      } else {
        loader = loader ? loader : default_loader;
      }
      $('body').append(`<div class="loading_wrapper init"><div class="backdrop_loading"></div><div class="loading__content">${loader}<p>${message || 'Cargando...'}</p></div></div>`);
      this.isLoading = true;
      // setTimeout(() => {
      //   if(this.isLoading) {
      //     $('.loading_wrapper.init').remove();
      //     this.isLoading = false;
      //   }
      // }, 10000);
    }
  }

  dismissAlt() {
    if(this.isLoadingAlt) {
      $('.loading_wrapper.alt').remove();
      this.isLoadingAlt = false;
    }
  }

  loadingShowAlt(loader?: string, message?: string) {
    if(!this.isLoadingAlt) {
      // console.log('loading');
      let default_loader = '<div class="loads-ellipsis"><div></div><div></div><div></div><div></div></div>';
      if(loader && loader == 'loading_2'){
        loader = '<div class="loadingio-spinner-dual-ball"><div class="ldio-dualball"><div></div><div></div><div></div></div></div>';
      } else {
        loader = loader ? loader : default_loader;
      }
      $('body').append(`<div class="loading_wrapper alt"><div class="backdrop_loading"></div><div class="loading__content">${loader}<p>${message || 'Cargando...'}</p></div></div>`);
      this.isLoadingAlt = true;
      // setTimeout(() => {
      //   if(this.isLoadingAlt) {
      //     $('.loading_wrapper.alt').remove();
      //     this.isLoadingAlt = false;
      //   }
      // }, 10000);
    }
  }

  // * Push Notification
  dismissPermissionNotification() {
    const $element = $('.loading_wrapper-permission-notification');
    if ($element.length) {
      $element.remove();
    }
  }

  permissionNotification() {
    $('body').append(`<div class="loading_wrapper-permission-notification"><div class="backdrop_loading"></div><div class="loading__content"><div class="loading__content-icon"><img src="/assets/icons/notification-bell-on.svg" /></div><div class="loading__content-info"><h4>Permitir notificaciones</h4><p>Haz clic en la opción "Permitir" que se encuentra en la parte superior para recibir notificaciones.</p><img src="/assets/img/notification-alert-default.png" /><p><small>En caso de no recibir la alerta, haz click en "Aceptar" e ingresa a "Recomendaciones".</small></p><button class="cy__btn btn__second" onclick="dismiss()">Aceptar</button></div><script>function dismiss() { const $element = $('.loading_wrapper-permission-notification'); if ($element.length) { $element.remove(); } }</script></div></div>`);
  }
}
